import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { EcaSecurityService } from '@drc-eca/eca-components-lib';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(private router: Router, private ecaSecurityService: EcaSecurityService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkLoggedIn(state.url);
    }

    canLoad(route: Route): Observable<boolean> {
        return this.checkLoggedIn(route.path);
    }

    checkLoggedIn(url: string): Observable<boolean> {
        return this.ecaSecurityService.isUserLoggedIn.pipe(
            take(1),    // to complete the observable as this is no longer checking the session state
            map(loginState => {
                if (loginState) {    // true if logged in
                    return true;
                } else {
                    this.router.navigate(['/login', { returnUrl: [`${url}`] }]);
                    return false;
                }
            })
        );
    }
}
